







import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class CloseModalButton extends Vue {
  @Prop({ type: String }) private readonly name!: string;
}
